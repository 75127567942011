<template>
  <el-main class="contactus-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        {{ $t('navbar.ContactUs') }}
      </div>
      <div class="content-section">
        <el-card class="box-card">
          <el-row class="contactus-row">
            <el-col class="content-title">
              {{ $t('ContactUs.Tel') }}
            </el-col>
            <el-col>
              (02) 6607- 2560
            </el-col>
          </el-row>
          <el-row class="contactus-row">
            <el-col class="content-title">
              {{ $t('ContactUs.Email') }}
            </el-col>
            <el-col>
              <a 
                href="mailto:service@data-sports.tw"
                :aria-label="$t('Aria.Mailto')"
              >
                service@data-sports.tw 
              </a>
            </el-col>
          </el-row>
        </el-card>
        
      </div>

    </section>

  </el-main>
</template>

<script>
import { DownloadFile } from '@/Mixins'
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'ContactUs',
  components: {
    MainContain
  },
  mixins: [DownloadFile]
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.contactus-container {
  color: $text_dark;
  font-size: 1rem;
  line-height: 2;
  .inner-container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
 
  .principle-content, .sitemape-content{
    padding: 0.625rem;
  }
  .content-section{
    .box-card{
      height: 300px;
      .contactus-row{
        padding: 0.5rem 0;

        .content-title{
          font-weight: bold;
        }
        a{
          color: $link;
        }
        a:hover{
          font-weight: bold;
        }
      }
    }
  }
}
</style>
